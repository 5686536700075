import StyleSheet from 'react-native-extended-stylesheet';

import {HEADER_HEIGHT, REDUCED_ATF_V2_VIDEO_HEIGHT_PERCENTAGE} from '@/components/organisms/Header';

export const styles = StyleSheet.create({
  content: {
    flex: 1,
    alignSelf: 'center',
  },
  contentReducedATFExperimentV2: {
    justifyContent: 'flex-end',
  },
  contentDesktop: {
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
  contentDesktopReducedATFExperiment: {
    minHeight: `calc(75vh - ${HEADER_HEIGHT}px)`,
  },
  media: {
    flex: 1,
  },
  mediaReducedATFExperimentV2: {
    minHeight: 360, // Necesario para que se vea bien el vídeo en el navegador de Facebook con sus barras
    height: `${REDUCED_ATF_V2_VIDEO_HEIGHT_PERCENTAGE}vh`,
  },
  contentTop: {
    justifyContent: 'center',
    flexDirection: 'column',
  },
  contentTopDesktop: {
    maxWidth: 464,
    flex: 1,
  },
  contentBullets: {
    marginBottom: '$space8',
    flexDirection: 'column',
    gap: '$space2',
  },
  contentBulletsReducedATFExperimentV1: {
    marginBottom: '$space5',
    flexDirection: 'column',
    gap: '$space2',
  },
  contentBulletsReducedATFExperimentV2: {
    marginBottom: '$space4',
    flexDirection: 'column',
    gap: '$space2',
  },
  contentBulletsDesktop: {
    gap: '$space4',
  },
  bullets: {
    textAlign: 'left',
    flexDirection: 'row',
  },
  bulletIcon: {
    marginRight: '$space4',
  },
  bulletIconReducedATFExperimentV2: {
    marginRight: '$space1',
  },
  cta: {
    maxWidth: 298,
  },
});
