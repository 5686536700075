import React, {useMemo} from 'react';
import {
  ALIGN,
  Button,
  BUTTON_VARIANT,
  COLOR,
  FLEX_DIRECTION,
  Icon,
  Layout,
  SPACE,
  Text,
  useDevice,
  View,
} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {scrollToQuiz} from '@/pages/Home/services';
import {useMendelContext} from '@/modules/ABTesting';
import {styles} from '../../Intro.styles';
import {IDS, TEXT} from '../../intro.definition';
import {Banner, TrustPilot} from '@/pages/Home/components/Intro/components';
import {getIntroConfig} from '@/pages/Home/components/Intro/services/getIntroConfig';

const {content, images} = getIntroConfig();

const IntroContentV1: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();

  const {freeTrial, previewOnHomePage} = useMendelContext();
  const expText = freeTrial.text?.cta;
  const CTA_TEXT = expText ? {id: expText} : content.cta;

  const bullets = useMemo(() => {
    return previewOnHomePage.bullets ? previewOnHomePage.bullets : content.bullets;
  }, [previewOnHomePage.bullets]);

  return (
    <View
      as="section"
      style={[
        screen.L && styles.content,
        screen.L && styles.contentDesktop,
        screen.L && styles.contentDesktopReducedATFExperiment,
      ]}
      nativeID="content-section"
    >
      <Banner />
      <Layout
        fullWidth={screen.L}
        style={[styles.contentTop, screen.L && styles.contentTopDesktop]}
        marginBottom={
          images.trustpilot
            ? {
                S: 0,
                M: 0,
              }
            : !screen.L
            ? SPACE.SPACE_6
            : SPACE.SPACE_8
        }
        marginTop={screen.L ? SPACE.SPACE_8 : SPACE.SPACE_4}
      >
        <Text
          color={COLOR.TEXT}
          heading
          level={2}
          marginBottom={{
            L: SPACE.SPACE_6,
            default: SPACE.SPACE_5,
          }}
          nativeID={IDS.TITLE}
        >
          {formatMessage(TEXT.TITLE)}
        </Text>
        <View
          style={[
            screen.L && styles.contentBullets,
            !screen.L && styles.contentBulletsReducedATFExperimentV1,
            screen.L && styles.contentBulletsDesktop,
          ]}
          nativeID={IDS.BULLETS}
        >
          {bullets.map(bullet => (
            <View style={styles.bullets} key={bullet.id}>
              <Icon name={'tick_fill'} style={styles.bulletIcon} />
              <Text color={COLOR.TEXT} detail={!screen.L} level={screen.L ? 3 : 1}>
                {formatMessage(bullet)}
              </Text>
            </View>
          ))}
        </View>
        <Button
          nativeID={IDS.CTA}
          style={[screen.L && styles.cta, !screen.L && {height: 48}]}
          onPress={scrollToQuiz}
          variant={BUTTON_VARIANT.PRIMARY}
        >
          {formatMessage(CTA_TEXT)}
        </Button>
        <TrustPilot />
      </Layout>
    </View>
  );
};

const MemoizedIntroContentV1 = React.memo(IntroContentV1);
export {MemoizedIntroContentV1 as IntroContentV1};
