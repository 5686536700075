import React from 'react';
import {StyleSheet} from 'react-native';
import {View} from '@lookiero/aurora';
import {styles} from './GradientView.styles';

interface VideoGradientViewProps {
  children?: React.ReactNode;
}

export const GradientView: React.FC<VideoGradientViewProps> = ({children}) => (
  <View style={styles.container}>
    <View style={[StyleSheet.absoluteFill, styles.gradientBottom]} />
    {children}
  </View>
);
