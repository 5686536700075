import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  steps: {
    gap: '$space8',
  },
  stepsReducedATFExperimentV2: {
    gap: '$space6',
  },
  image: {
    borderRadius: '$borderRadius4',
  },
  stepContainer: {
    flex: 1,
  },
});
