import React, {useMemo} from 'react';
import StyleSheet from 'react-native-extended-stylesheet';
import {useDevice} from '@lookiero/aurora';
import {ASSETS_URL} from '@/config';

import {Video} from '@/components/atoms/Video';
import {HEADER_HEIGHT} from '@/components/organisms/Header';
import {useMendelContext} from '@/modules/ABTesting';

const getVideoUrl = (folder = 'grw_NicaOnHP'): {desktop: string; mobile: string} => ({
  desktop: `${ASSETS_URL}/videos/intro/${folder}/intro_desktop.mp4`,
  mobile: `${ASSETS_URL}/videos/intro/${folder}/intro_mobile.mp4`,
});

const styles = StyleSheet.create({
  video: {
    objectPosition: 'top',
  },
  videoDesktop: {
    maxHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
  },
});

interface IntroVideoProps {
  onError?: () => void;
  children?: React.ReactNode;
}

export const IntroVideo: React.FC<IntroVideoProps> = ({onError, children}) => {
  const {screen} = useDevice();
  const {reducedATF} = useMendelContext();

  const videoSrc = useMemo(() => {
    return getVideoUrl();
  }, []);

  const videoStyle = screen.L ? styles.videoDesktop : {};

  return (
    <Video
      src={videoSrc}
      style={{...styles.video, ...videoStyle}}
      onError={onError}
      fullHeight={screen.L || reducedATF.v2}
      width={!screen.L && reducedATF.v1 ? 375 : 640}
      height={!screen.L && reducedATF.v1 ? 280 : 800}
    >
      {children}
    </Video>
  );
};
