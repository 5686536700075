import React, {FC} from 'react';
import {SPACE, Text, useDevice, TitleIcon} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {useHomeContext} from '@/pages/Home/context';
import {IDS, TEXT} from './HowItWorks.definition';
import {useSteps} from './hooks/useSteps';
import {Section} from '@/components/atoms/Section';
import {StepViewer} from '@/components/atoms';
import {useMendelContext} from '@/modules/ABTesting';

const HowItWorks: FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  const {onLoadContent} = useHomeContext();
  const steps = useSteps();
  const {reducedATF} = useMendelContext();

  return (
    <Section nativeID={IDS.SECTION}>
      <TitleIcon text={formatMessage(TEXT.TITLE)} icon="double_half_circle" />
      <Text
        heading
        level={screen.L ? 2 : 3}
        marginTop={{L: SPACE.SPACE_2, default: reducedATF.control ? SPACE.SPACE_4 : SPACE.SPACE_2}}
        marginBottom={reducedATF.control || screen.L ? SPACE.SPACE_8 : SPACE.SPACE_6}
      >
        {formatMessage(TEXT.SUBTITLE)}
      </Text>
      <StepViewer steps={steps} onImageLoad={onLoadContent} />
    </Section>
  );
};

const MemoizedHowItWorks = React.memo(HowItWorks);
export {MemoizedHowItWorks as HowItWorks};
