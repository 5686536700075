import React, {useMemo} from 'react';
import {useDevice} from '@lookiero/aurora';

import {Device} from '@/types/Device';
import {Image, ImageSource} from '@/components/atoms';
import {IMAGES_URL} from '@/config';
import {useHomeContext} from '@/pages/Home/context';
import {useMendelContext} from '@/modules/ABTesting';

const devices: Device[] = ['Desktop', 'Mobile'];
const folder = 'intro';

const buildImages = (
  experiment = 'rebrand',
  variation = 'grw_NicaOnHP',
): {
  Desktop: ImageSource;
  Mobile: ImageSource;
} => {
  const images = {
    Desktop: {},
    Mobile: {},
  } as {Desktop: ImageSource; Mobile: ImageSource};
  devices.forEach(d => {
    images[d].default = `${IMAGES_URL}/${folder}/${experiment}/${variation}/${d}/intro@2x.jpg`;
    images[d].avif = `${IMAGES_URL}/${folder}/${experiment}/${variation}/${d}/intro@2x.avif`;
    images[d].webp = `${IMAGES_URL}/${folder}/${experiment}/${variation}/${d}/intro@2x.webp`;
  });
  return images;
};

interface IntroImageProps {
  children?: React.ReactNode;
}

export const IntroImage: React.FC<IntroImageProps> = ({children}) => {
  const {screen} = useDevice();
  const {onLoadContent} = useHomeContext();
  const {reducedATF} = useMendelContext();

  const introImages = useMemo(() => {
    return buildImages();
  }, []);

  const introImageSource = introImages[screen.L ? 'Desktop' : 'Mobile'];

  return (
    <Image
      height={900}
      width={720}
      fullHeight={screen.L || reducedATF.v2}
      alt="intro"
      src={introImageSource}
      onLoad={onLoadContent}
      imageStyle={{objectPosition: 'top'}}
    >
      {children}
    </Image>
  );
};
