import {HOW_IT_WORK_CONFIG} from '../config/howItWorksConfig';
import {IMAGES_URL} from '@/config';
import type {Step} from '@/components/atoms';
import {ImageSource} from '@/components/atoms';

type Device = 'Desktop' | 'Mobile';

interface ImageContainer {
  Desktop: ImageSource[];
  Mobile: ImageSource[];
}

const devices: Device[] = ['Desktop', 'Mobile'];
const carrousel = ['01', '02', '03'];
const folder = 'how-it-works';

const buildImages = (experiment: string, variation: string) => {
  const images: ImageContainer = {
    Desktop: [],
    Mobile: [],
  };
  devices.forEach(d => {
    const imageObjects: ImageSource[] = [];
    carrousel.forEach(carrouselPicName => {
      const image: ImageSource = {
        default: `${IMAGES_URL}/${folder}/${experiment}/${variation}/${d}/${carrouselPicName}@2x.jpg`,
        avif: `${IMAGES_URL}/${folder}/${experiment}/${variation}/${d}/${carrouselPicName}@2x.avif`,
        webp: `${IMAGES_URL}/${folder}/${experiment}/${variation}/${d}/${carrouselPicName}@2x.webp`,
      };
      imageObjects.push(image);
    });
    images[d] = imageObjects;
  });
  return images;
};

export const getSteps = (
  isDesktop: boolean,
  experiment: string = 'rebrand',
  variation: string = 'grw_NicaOnHP',
): Step[] => {
  const steps = HOW_IT_WORK_CONFIG;
  const images = buildImages(experiment, variation);
  steps.forEach((step, index) => (step.image = images[isDesktop ? 'Desktop' : 'Mobile'][index]));
  return steps as Step[];
};
