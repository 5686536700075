import React, {FC} from 'react';
import {ALIGN, Box, DISPLAY, FLEX_DIRECTION, SPACE, Text, useDevice, View} from '@lookiero/aurora';
import {Image} from '@/components/atoms';
import {useIntl} from '@/modules/I18N';
import {Step} from './types/Step';
import {styles} from './StepViewer.styles';
import {useMendelContext} from '@/modules/ABTesting';

interface StepViewer {
  steps: Step[];
  onImageLoad?: () => void;
}

export const StepViewer: FC<StepViewer> = ({steps, onImageLoad}) => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();
  const {reducedATF} = useMendelContext();

  const reducedATFExperimentMobileEnabled = !(reducedATF.control || screen.L);

  return (
    <View
      alignItems={ALIGN.LEFT}
      display={DISPLAY.FLEX}
      flexDirection={screen.L ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN}
      style={[reducedATF.v2 ? styles.stepsReducedATFExperimentV2 : styles.steps]}
    >
      {steps.map(({title, summary, image}, index) => (
        <Box key={title.id} display={DISPLAY.FLEX} style={styles.stepContainer}>
          <View marginBottom={SPACE.SPACE_6}>
            <Image
              height={reducedATFExperimentMobileEnabled ? 328 : 360}
              width={reducedATFExperimentMobileEnabled ? 328 : 288}
              lazyLoad
              onLoad={onImageLoad}
              src={image}
              animation="slide"
              imageStyle={styles.image}
            />
          </View>
          <Text as="h3" action level={2} marginBottom={SPACE.SPACE_1} nativeID={`step-${index + 1}-title`}>
            {formatMessage(title, {values: title.params})}
          </Text>
          <Text as="p" level={3} nativeID={`step-${index + 1}-summary`}>
            {formatMessage(summary, {values: summary.params})}
          </Text>
        </Box>
      ))}
    </View>
  );
};
