import React, {useState} from 'react';

import {IntroImage} from './IntroImage';
import {IntroVideo} from './IntroVideo';

interface IntroMediaProps {
  children?: React.ReactNode;
}

export const IntroMedia: React.FC<IntroMediaProps> = ({children}) => {
  const [videoError, setVideoError] = useState(false);

  if (!videoError) {
    return <IntroVideo onError={() => setVideoError(true)}>{children}</IntroVideo>;
  }

  return <IntroImage>{children}</IntroImage>;
};
