import React, {useMemo} from 'react';
import {
  ALIGN,
  Button,
  BUTTON_VARIANT,
  COLOR,
  FLEX_DIRECTION,
  Icon,
  Layout,
  SPACE,
  Text,
  useDevice,
  View,
} from '@lookiero/aurora';

import {useIntl} from '@/modules/I18N';
import {scrollToQuiz} from '@/pages/Home/services';
import {useMendelContext} from '@/modules/ABTesting';
import {styles} from '../../Intro.styles';
import {IDS, TEXT} from '../../intro.definition';
import {Banner, TrustPilot} from '@/pages/Home/components/Intro/components';
import {getIntroConfig} from '@/pages/Home/components/Intro/services/getIntroConfig';

const {content, images} = getIntroConfig();

const IntroContentControl: React.FC = () => {
  const {screen} = useDevice();
  const {formatMessage} = useIntl();

  const {freeTrial, previewOnHomePage} = useMendelContext();
  const expText = freeTrial.text?.cta;
  const CTA_TEXT = expText ? {id: expText} : content.cta;

  const bullets = useMemo(() => {
    return previewOnHomePage.bullets ? previewOnHomePage.bullets : content.bullets;
  }, [previewOnHomePage.bullets]);

  return (
    <View as="section" style={[styles.content, screen.L && styles.contentDesktop]} nativeID="content-section">
      <Banner />
      <Layout
        fullWidth={screen.L}
        style={[styles.contentTop, screen.L && styles.contentTopDesktop]}
        marginBottom={images.trustpilot ? {S: 0, M: 0} : SPACE.SPACE_8}
        marginTop={SPACE.SPACE_8}
      >
        <Text
          heading
          level={screen.L ? 1 : 2}
          marginBottom={{L: SPACE.SPACE_6, default: SPACE.SPACE_4}}
          nativeID={IDS.TITLE}
        >
          {formatMessage(TEXT.TITLE)}
        </Text>
        <View style={[styles.contentBullets, screen.L && styles.contentBulletsDesktop]} nativeID={IDS.BULLETS}>
          {bullets.map(bullet => (
            <View style={styles.bullets} key={bullet.id}>
              <Icon name="tick_fill" style={styles.bulletIcon} />
              <Text level={screen.L ? 2 : 3} style={{alignSelf: ALIGN.CENTER}}>
                {formatMessage(bullet)}
              </Text>
            </View>
          ))}
        </View>
        <Button nativeID={IDS.CTA} style={screen.L && styles.cta} onPress={scrollToQuiz}>
          {formatMessage(CTA_TEXT)}
        </Button>
        <TrustPilot />
      </Layout>
    </View>
  );
};

const MemoizedIntroContentControl = React.memo(IntroContentControl);
export {MemoizedIntroContentControl as IntroContentControl};
