import React from 'react';
import {FLEX_DIRECTION, useDevice, View} from '@lookiero/aurora';
import {useMendelContext} from '@/modules/ABTesting';
import {styles} from './Intro.styles';
import {Banner, IntroMedia} from './components';
import {IDS} from './intro.definition';
import {IntroContentV1} from '@/pages/Home/components/Intro/components/IntroContent/IntroContentV1';
import {IntroContentControl} from '@/pages/Home/components/Intro/components/IntroContent/IntroContentControl';
import {IntroContentV2} from '@/pages/Home/components/Intro/components/IntroContent/IntroContentV2';

const Intro: React.FC = () => {
  const {screen} = useDevice();

  const {reducedATF} = useMendelContext();

  const renderContentSection = () => {
    switch (reducedATF.variant) {
      case 'v1':
        return <IntroContentV1 />;
      case 'v2':
        return <IntroContentV2 />;
      default:
        return <IntroContentControl />;
    }
  };

  return (
    <View as="section" flexDirection={screen.L ? FLEX_DIRECTION.ROW : FLEX_DIRECTION.COLUMN} nativeID={IDS.SECTION}>
      {(screen.L || !reducedATF.v2) && renderContentSection()}
      <View
        as="aside"
        nativeID={IDS.IMAGE}
        style={!screen.L && reducedATF.v2 ? styles.mediaReducedATFExperimentV2 : styles.media}
      >
        <IntroMedia>{!screen.L && reducedATF.v2 && <IntroContentV2 />}</IntroMedia>
      </View>
      {!screen.L && reducedATF.v2 && <Banner />}
    </View>
  );
};

const MemoizedIntro = React.memo(Intro);
export {MemoizedIntro as Intro};
