import StyleSheet from 'react-native-extended-stylesheet';

export const styles = StyleSheet.create({
  container: {
    zIndex: 10,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gradientTop: {
    justifyContent: 'flex-end',
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 11.16%)',
  },
  gradientBottom: {
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.00) 41.84%, #000 99.94%)',
  },
});
